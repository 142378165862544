import './sidebar.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import sidebarLinks from './sidebar-links';

export default function Sidebar() {
  const [showSubList, setShowSublist] = useState({ users: false, organisations: false, records: false });

  const navHeaderClick = (source) => {
    switch (source) {
      case 'users': 
        setShowSublist({users: true, organisations: false, records: false });
        break;

      case 'organisations': 
        setShowSublist({users: false, organisations: true, records: false });
        break;

      case 'records': 
        setShowSublist({users: false, organisations: false, records: true });
        break;

      default:
        setShowSublist({users: false, organisations: false, records: false })
        break;
    }
  }

  const navRows = [];
  for (const [header, subLinks] of Object.entries(sidebarLinks)) {
    navRows.push(
      <div key={header.toLowerCase()}>
        <li className='Nav-header' onClick={() => {navHeaderClick(header.toLowerCase())}}><h4>{header}</h4></li>
        {
          showSubList[header.toLowerCase()] &&
          <div id={header.toLowerCase()} className='Nav-link-list'>
            {
              Object.entries(subLinks).map(([name, url] ) => {
                return <Link to={url} className='Nav-link-list-item'>{name}</Link>
              })
            }
          </div>
        }
      </div>
    )
  }

  return (
    <div className='Sidebar'>
      <ul className='Nav'>
        { navRows }
      </ul>
    </div>
  )
}