// object containing the sidebar headers and the links contained under the headers
// the link key is used as the display name and its value must be the url that it navigates to
/**
 * {
 *    sidebarHeader: {
 *        linkDisplayName: linkURL
 *    }
 * }
 */
const sidebarLinks = {
  'Users': {
    'Bulk upload': '/users/bulk-upload',
    'Groups': '/users/groups'
  },
  'Organisations': {
    'SSO Setup': '/organisations/sso'
  },
  'Records': {
    'Placeholder': '/records'
  }
}

export default sidebarLinks;
