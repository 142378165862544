import { createBrowserRouter } from 'react-router-dom';
import App from './app/App';
import Home from './app/home/home'
import BulkUpload from './app/users/bulk-upload/bulk-upload';
import Groups from './app/users/groups/groups';
import Records from './app/records/records';
import SingleSignOnSetup from "./app/organisations/sso/sso";

// Here we define the app routes
export default createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'home',
        element: <Home />
      },
      {
        path: 'users',
        children: [
          {
            path: 'bulk-upload',
            element: <BulkUpload />
          },
          {
            path: 'groups',
            element: <Groups />
          }
        ]
      },
      {
        path: 'organisations',
        children: [
          {
            path: 'sso',
            element: <SingleSignOnSetup />
          }
        ]
      },
      {
        path: 'records',
        element: <Records />
      }
    ]
  }
]);
