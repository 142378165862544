import './bulk-upload.css';

export default function BulkUpload() {
  return (
    <div className='wrapper'>
      <h1>Bulk uploading users via CSV</h1>
      <div className='content'>
        <h2>How to perform this action</h2>
        <ol class='ordered-list'>
          <li>Download CSV import template 
            from the <a href="https://betteroffcalculator.co.uk/users" target='_blank' rel="noreferrer">users</a> page, 
            under the <code>Bulk actions</code> dropdown,
          </li>
          <li>Fill in the template with the users you want to upload,</li>
          <li>Use the <code>Import via CSV</code> button in the same dropdown as step 1,</li>
          <li>Follow the steps in the resulting form and submit.</li>
        </ol>

        <h2>What happens after you submit the form</h2>
        <ol class='ordered-list'>
          <li>
            The list of users are checked for duplicates and the user will be asked 
            to remove them from the file if any are found.
          </li>
          <li>
            The DB is then searched for any existing users, if any are found we:
          </li>
          <ul class='ordered-list'>
            <li>Send them an email, inviting them to the new organisation</li>
            <li>
              The email contains a link that when clicked will assign the user to the new organisation
              and update their customisation to match
            </li>
          </ul>
          <li>
            We then start to create the new users who dont already exist, this includes: 
          </li>
          <ul class='ordered-list'>
            <li>  
              creating user in the requested organisation with that organisations customisation
            </li>
            <li>  
              assigning the user to the requested groups and subgroups
            </li>
            <li>  
              sending the newly created user a welcome email, allowing them to create a password
            </li>
          </ul>
        </ol>
      </div>
    </div>
  )
}
