import './groups.css';
import Diagram from './groups-diagram.png';

export default function BulkUpload() {
  return (
    <div>
      <h1>Groups and sub groups</h1>
      <div className='group-wrapper'>
        <div className='group-content'>
          <h2>What are they?</h2>
          <p align='justify'>
            Groups are used to split the organisation into divisions so that clients can manage their users easier. 
            Groups are then divided into subgroups which allow organisation to control which users can see and interact 
            with which cases. 
          </p>
          <p align='justify'>
            The diagram demonstrates how we can use sub groups to partition users and restrict their access to 
            cases from other groups/subgroups. Groups can be created via the admin panel.
          </p>
        </div>
        <div className='image-wrapper'>
          <img className='diagram' src={Diagram} alt='Diagram of how oranisation, groups and users are connected'/>
          <figcaption>How organisations, groups, subgroups and users are connected.</figcaption>
        </div>
      </div>
    </div>
  )
}
