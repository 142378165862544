import './App.css';
import { Outlet } from 'react-router-dom'
import Logo from '../assets/pip-logo-white.png'
import Sidebar from './navigation/sidebar';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    if (window.location.pathname === '/') {
      window.location.pathname = '/home';
    }
  }, []);

  return (
    <div className='App'>
      <header className='Header'>
        <h2 style={{ marginRight: '30vw', marginLeft: '35vw' }}>Better Off Calculator Handbook</h2>
        <img className='Logo' src={Logo} alt='Policy in Practice logo'></img>
      </header>
      <div className='Content-row'>
        <Sidebar />
        {/** <Outlet /> is where RouterProvider (in index.js) renders content */}
        <div className='Content'>
          <Outlet />
        </div>
        </div>
    </div>
  );
}

export default App;
