import './sso.css';

export default function SingleSignOnSetup() {
  return (
    <div className='wrapper'>
      <h1>Setting up Single Sign-On using MS Azure</h1>
      <div className='content'>
        <div align='justify'>
          You will need to make two changes; one in AWS Cognito and another in DataGrip.
        </div>
        <h2>AWS Cognito</h2>
        <div align='justify'>
          Choose the correct user pool, this will usually be Calculator Production User Pool.
        </div>
        <h3>Sign-in experience</h3>
        <div className='text'>
          Under sign-in experience, click Add identity provider, and choose SAML. Enter provider name
          and add attributes (email, family_name, and client). You will also need to upload a metadata document which will
          be provided by the client after they have done their part of the setup (please see the <a href="https://docs.google.com/document/d/1jzd81QrpR-Fk5NkFWWgQhtmXR-sXlVnM_pLmhWhuwXo/edit#heading=h.kf51jkadqe1t" target="_blank" rel="noreferrer">Microsoft Azure documentation</a> on how to help the client with the process).
        </div>
        <h3>App integration</h3>
        <div className='text'>
          Navigate to app integration and click on Create app client. Enter the App client name and add
          the following Authentication flows: ALLOW_CUSTOM_AUTH, ALLOW_REFRESH_TOKEN_AUTH, ALLOW_USER_SRP_AUTH.
          Under the URL enter a placeholder URL for now, otherwise you won't be able to create the client, e.g., https://www.policyinpractice.co.uk.
          Remove the cognito user pool in identity providers and add the one you created in the previous step (this will
          usually be the name of the organisation). Under OpenID Connect scopes you should have: email, openid, and profile.
          Click Create app client. Now that you have created the client, there will be a ClientID next to the name. Replace the placeholder URL with the <a href="https://policyinpractice.auth.eu-west-2.amazoncognito.com/oauth2/authorize?client_id=0000000&response_type=code&scope=email+openid+profile&redirect_uri=https%3A%2F%2Fbetteroffcalculator.co.uk%2Fsso%2Fregister%2F0000000&state=/history" target="_blank" rel="noreferrer"> this</a>,
          and the zeros within the URL with the Client ID. Keep that URL handy, you will need it again shortly.
        </div>
        <h2>DataGrip</h2>
        <div className='text'>
          Search the correct DB for the organisation you want to create the SSO for. Fill in orgHint (usually the organisation's name),
          Add the Client ID from Cognito to the clientId column and the URL to the redirectURL column. You're done!
        </div>
      </div>
    </div>
  )
}
